import { createApp } from 'vue';
import router from './router';
import App from './App.vue';
import VCalendar from 'v-calendar';
import PrimeVue from 'primevue/config';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import VueGtag from "vue-gtag";
import mitt from 'mitt';
import { GOOGLE_MAPS_KEY, GOOGLE_ANALYTICS_KEY } from "./services/config";
import initializeKeycloak from './keycloak';

const emitter = mitt();

async function initializeKeycloakInstance() {
  try {
    const keycloak = await initializeKeycloak();
    await keycloak.init({ onLoad: 'check-sso' });
    return keycloak;
  } catch (error) {
    console.warn("Failed to initialize Keycloak:", error);
    return null; 
  }
}

async function startApp() {
  const keycloak = await initializeKeycloakInstance();

  const app = createApp(App);

  if (keycloak) {
      app.provide('keycloak', keycloak);
  }

  app.directive('tooltip', Tooltip);

  app.use(ToastService);
  app.use(PrimeVue);
  app.use(VueLoading);
  app.use(VCalendar, {});
  app.use(router);

  app.use({
    install(app) {
      app.config.globalProperties.emitter = emitter;
    },
  });

  try {
    app.use(VueGoogleMaps, {
      load: {
        key: GOOGLE_MAPS_KEY,
      },
    });
  } catch (error) {
    console.error("Failed to load Google Maps:", error);
  }

  try {
    app.use(VueGtag, {
      appName: 'turing',
      pageTrackerScreenviewEnabled: true,
      config: { id: GOOGLE_ANALYTICS_KEY },
    }, router);
  } catch (error) {
    console.error("Failed to load Google Analytics:", error);
  }

  app.mount('#app');
}

startApp();
