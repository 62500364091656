<template>
  <Toast />
  <div class="head-btn">
    <h2>Listagem de dispositivos</h2>
    <div class="groupCadastros">
      <GroupbtnAdmin></GroupbtnAdmin>
    </div>
    <div class="d-grid gap-2 col-2" style="justify-items: self-end" >
      <router-link to="/cadastro/dispositivos"  v-if="isSuper">
        <button class="btn btn-login" type="button" style="line-height: 2rem">
          Cadastrar
        </button>
      </router-link>
    </div>
  </div>
  <div class="tableBox">
    <DataTable
      :value="products"
      :paginator="true"
      :rows="50"
      class="paginator-custom"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 20, 50]"
      responsiveLayout="scroll"
      v-model:selection="selectedProduct1"
      v-model:filters="filters"
      selectionMode="single"
      dataKey="id"
      @rowSelect="onRowSelect"
      currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}"
      filterDisplay="row"
    >
      <template #empty>Sem Dados Disponíveis</template>
      <Column field="created_at" header="Data de Ativação" :sortable="true" style="max-width: 12rem" :showFilterMenu="false">
        <template #body="{ data }">
            {{ data.created_at }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              v-model="filterModel.value"
              type="text"
              @input="filterCallback()"
              class="p-column-filter"
              placeholder="Pesquise pelo Data"
            />
          </template>
      </Column>
      <Column field="id" header="ID" style="max-width: 15rem" :showFilterMenu="false">
        <template #body="{ data }">
          {{ data.id }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            type="text"
            @input="filterCallback()"
            class="p-column-filter"
            placeholder="Pesquise pelo ID"
          />
        </template>
      </Column>
      <Column field="serial_number" header="ID do Dispositivo (Serial Number)" style="max-width: 15rem" :showFilterMenu="false">
        <template #body="{ data }">
          {{ data.serial_number }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            type="text"
            @input="filterCallback()"
            class="p-column-filter"
            placeholder="Pesquise pelo Serial Number"
          />
        </template>
      </Column>
      <Column field="type" header="Tipo" :showFilterMenu="false" style="max-width: 10rem" :showClearButton="false">
        <template #body="{ data }">{{data.type === 'noise' ? 'Ruído' : data.type === 'pressure' ? 'Pressão' : 'Vazão'}}</template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @change="filterCallback()"
            :options="types"
            placeholder="Selecione "
            class="p-column-filter"
            style="max-width: 10rem"
            :showClear="true"
          >
            <template #option="slotProps">
              <span>{{slotProps.option}}</span>
            </template>
          </Dropdown>
        </template>
      </Column>
      <Column field="clientsLabel" header="Cliente" style="max-width: 15rem" :showFilterMenu="false">
        <template #body="{ data }">
          {{ data.clientsLabel }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            type="text"
            @input="filterCallback()"
            class="p-column-filter"
            placeholder="Pesquise Pelo Cliente"
          />
        </template>
      </Column>
      <!-- <Column field="status" header="Ativo">
        <template #body="{ data }">
          {{ data.status === "ATIVADO" ? "Sim" : "Não" }}
        </template>
      </Column> -->
    </DataTable>
  </div>
  <Dialog
    v-if="isSuper"
    header="Editar"
    v-model:visible="displayBasic"
    :style="{ width: '75vw' }" contentClass="content"
  >
    <div class="row">
      <p style="font-size:1rem">* Campos Obrigatórios</p>
      <div class="col-sm-6 form-floating">
        <Multiselect
          v-model="type"
          :columns="{ container: 12, label: 4, wrapper: 6 }"
          :placeholder="type"
          class="multiselect-custom form-select"
          :options="tiposDispositivos"
        />
        <label for="floatingSelect" style="margin-left: 15px;padding: 12px;">Tipo do Dispositivo*</label>
      </div>
      <div class="col-sm-6">
        <div class="form-floating">
            <input
              type="text"
              class="form-control"
              placeholder="ID"
              v-model="id"
              autocomplete="off"
              aria-describedby="addon-wrapping"
              id="floatingInputGrid"
              disabled
            />
            <label for="floatingInputGrid">ID (Emei ou DevEui) *</label>
          </div>
        </div>
        <div class="col-sm-6">
        <div class="form-floating">
          <input
              type="text"
              class="form-control"
              placeholder="ID do Dispositivo (Serial Number)"
              v-model="serial_number"
              autocomplete="off"
              aria-describedby="addon-wrapping"
              id="floatingInputGrid"
              disabled
            />
          <label for="floatingInputGrid">ID do Dispositivo (Serial Number) *</label>
        </div>
      </div>
      <div class="col-sm-6 form-floating" >
          <Multiselect
          :columns="{ container: 12, label: 4, wrapper: 6 }"
          v-model="selVersao"
          placeholder="Selecione"
          class="multiselect-custom form-select"
          :options="listaVersao"
          id="floatingInputGrid"
        />
        <label for="floatingSelect" style="margin-left: 15px;padding: 10px;">Versão *</label>
      </div>
      <div class="col-sm-6 form-floating" >
          <Multiselect
          :columns="{ container: 12, label: 4, wrapper: 6 }"
          v-model="clients"
          placeholder="Selecione"
          class="multiselect-custom form-select"
          :options="selClient"
          id="floatingInputGrid"
        />
        <label for="floatingSelect" style="margin-left: 15px;padding: 10px;">Clientes *</label>
      </div>
      <div class="col-sm-6">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Setup"
            v-model="setup"
            aria-describedby="addon-wrapping"
             id="floatingInputGrid"
          />
          <label for="floatingInputGrid">Setup</label>
        </div>
      </div>  
    </div>
    <div class="form-check">
      <input
        class="form-check-input"
        v-model="macrometer"
        type="checkbox"
        value=""
        id="flexCheckDefault"
      />
      <label class="form-check-label" for="flexCheckDefault"> Macromedidor </label>
    </div>
    <template #footer>
      <button @click="closeBasic" class="btn btn-danger">Cancelar</button>
      <button @click="saveEdit()" class="btn btn-filtrar">Salvar</button>
    </template>
  </Dialog>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { ref, onMounted } from "vue";
import Dialog from "primevue/dialog";
import { useLoading } from "vue-loading-overlay";
import Multiselect from "@vueform/multiselect";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import { listClients, upDateDevice, filterDevice, listSchema } from "../services/crud.js";
import { store } from "../services/store.js";
import GroupbtnAdmin from "../components/groupbtnAdmin.vue";
import { FilterMatchMode } from 'primevue/api';
import Dropdown from "primevue/dropdown";
import InputText from 'primevue/inputtext';
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
const $loading = useLoading();

export default {
  components: {
    Column,
    DataTable,
    Toast,
    GroupbtnAdmin,
    Multiselect,
    Dialog,
    InputText,
    Dropdown
  },
  data() {
    return {
      temp: null,
      isSuper: (store.type_user == 1) ? true : false,
      endereco: "",
      value: "",
      listaDeClientes: [],
      dataForm: {},
      tiposDispositivos: [
        { label: "Vazão", value: "flow" },
        { label: "Pressão", value: "pressure" },
        { label: "Ruído", value: "noise" },
      ],

      placeholder: "Cliente",
      arrClients: [],
    };
  },
  methods: {
    saveEdit: async function () {
      const dataUpdate = {
        address: this.dataForm.address,
        client_id: this.clients,
        id: this.id,
        status: this.dataForm.status,
        type: this.type,
        serial_number: this.serial_number,
        tags: this.tags,
        setup: this.setup,
        version: this.selVersao,
        macrometer: this.macrometer
      };
      if (this.setup.length > 0) {
        try {
          dataUpdate.setup = JSON.parse(this.setup);
          this.edicaoDisp(dataUpdate)
        } catch (e) {
          this.showErrorSetup()
        }
      } else {
        dataUpdate.setup = {};
        this.edicaoDisp(dataUpdate)
      }      
    },
    async edicaoDisp(dataUpdate){
      if (!this.type || !this.type.trim() || !this.id || !this.id.trim() || !this.serial_number || !this.serial_number.trim() || !this.clients || !this.selVersao) {        
        this.showError()
      } else {
        await upDateDevice(dataUpdate)
          .then((response) => {
            if (response.status >= 200 && response.status <= 299) {
              this.showsuccess()
              this.closeBasic()
            }
          })
          .catch((err) =>
            this.showErrorServer(err)
          );
      }
      this.dataForm = {}
      dataUpdate = {}
    }
  },
  watch: {
    selectedProduct1: function (val) {
      const dataF = {
        address: val.address,
        created_at: val.created_at,
        id: val.id,
        status: val.status,
        type: val.type,
        serial_number: val.serial_number,
        setup: val.setup,
        clients: val.clients,
        selVersao: val.version,
        macrometer: val.macrometer
      };
      this.dataForm = dataF;
    },
  },
  setup() {
    let listClientsArr = [];
    let listaClientes= [];
    let listVersionArr = [];
    let listaVersoes= [];
    const products = ref();
    const displayBasic = ref(false);
    const type = ref();
    const id = ref();
    const serial_number = ref();
    const macrometer = ref();
    const tags = ref();
    const created_at = ref();
    const client_id = ref();
    const version_id = ref();
    const clients = ref();
    const selVersao = ref();
    const setup = ref();
    let clientsLabel = ref();
    const selClient = ref();
    const listaVersao = ref();
    const selectedProduct1 = ref();
    const types = ref(['Pressão', 'Ruído','Vazão']);
    let listSensores = ref([]);
    const filters = ref({
      serial_number: { value: null, matchMode: FilterMatchMode.CONTAINS },
      clientsLabel: { value: null, matchMode: FilterMatchMode.CONTAINS },
      id: { value: null, matchMode: FilterMatchMode.CONTAINS },
      created_at: { value: null, matchMode: FilterMatchMode.CONTAINS },
      type: { value: null, matchMode: FilterMatchMode.EQUALS },
    });

    const closeBasic = () => {
      displayBasic.value = false;
      getDevices()
    };

    const getDevices = async () =>{
      let aux = [];
      let auxS = [];
      let arrAux = [];
      let arrData = [];
      let auxDevice = {};

      if (store.type_user != 1) {
        auxDevice = {
          client_id : store.client_id
        }
      } 

      await listClients()
      .then((response) => {
        listClientsArr = [];
        clients.value = response.data;
        for (let i = 0; i < clients.value.length; i++) {
          listClientsArr.push({
            label: clients.value[i].trading_name,
            value: clients.value[i].id,
          });
        }
        listaClientes = listClientsArr;
      })
      .catch(() => console.log("erro"));

      await listSchema()
      .then((response) => {
        listVersionArr = [];
        selVersao.value = response.data;
        for (let i = 0; i < selVersao.value.length; i++) {
          listVersionArr.push({
            label: selVersao.value[i].version,
            value: selVersao.value[i].version,
          });
        }
        listaVersoes = listVersionArr;
      })
      .catch(() => console.log("erro"));

      await filterDevice(auxDevice)
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
             aux = listClientsArr.filter(res =>{
               return res.value == response.data[i].client_id;
              })
              auxS = listVersionArr.filter(res =>{
               return res.value == response.data[i].version;
              })
             arrData = {
                brand : response.data[i].brand,
                client_id: response.data[i].client_id,
                created_at: new Date(response.data[i].created_at).toLocaleString("pt-br"),
                id: response.data[i].id,
                install_point_id: response.data[i].install_point_id, 
                serial_number: response.data[i].serial_number,
                setup: response.data[i].setup,
                tags: response.data[i].tags,
                type: response.data[i].type,
                updated_at: response.data[i].updated_at,
                user_id: response.data[i].user_id,
                clients: aux.length > 0 ? aux[0].value : 'Não Informado',
                clientsLabel: aux.length > 0 ? aux[0].label : 'Não Informado',
                version: auxS.length > 0 ? auxS[0].label : 'Não Informado',
                macrometer: response.data[i].macrometer
              }
              arrAux.push(arrData)
          }
          products.value = arrAux;
        })
        .catch(() => console.log("erro"));
    };

    onMounted(async () => {
      const loader = $loading.show({});
      getDevices();
      loader.hide();
    });

    const onRowSelect = (event) => {
      const clientFilter = listClientsArr.filter((a) => {
        return event.data.client_id === a.value;
      });
      if (clientFilter.length == 0 ){
        client_id.value = clientFilter[0]
      } else {
        client_id.value = clientFilter[0].label;
      }

      const versionFilter = listVersionArr.filter((a) => {
        return event.data.version === a.value;
      });
            
      if (versionFilter.length == 0 ){
        version_id.value = versionFilter[0]
      } else {
        version_id.value = versionFilter[0].label;
      }
      type.value = event.data.type;
      serial_number.value = event.data.serial_number;
      id.value = event.data.id;
      tags.value = event.data.tags;
      setup.value = JSON.stringify(event.data.setup);
      clients.value = event.data.clients;
      selClient.value = listaClientes;
      selVersao.value = event.data.version;
      listaVersao.value = listaVersoes;
      displayBasic.value = true;
      macrometer.value = event.data.macrometer;
    };
    const toast = useToast();
    const showError = () => {
      toast.add({severity:'error', summary: 'Error', detail:'Campos Obrigatórios não foram preenchidos!', life: 2500});
    }
    const showErrorServer = (error) => {
      toast.add({severity:'error', summary: 'Error', detail: error, life: 2500});
    }
    const showsuccess = () => {
      toast.add({severity:'success', detail:" Dados de dispositivo alterados com sucesso! ", life: 2500});
    }
    const showErrorSetup = () => {
      toast.add({severity:'error', detail:"Dados de entrada do Setup foram inseridos incorretamente.", life: 2500});
    }
    return {
      showError,
      showErrorSetup,
      showErrorServer,
      showsuccess,
      client_id,
      products,
      listSensores,
      onRowSelect,
      selectedProduct1,
      closeBasic,
      displayBasic,
      clients,
      listClients,
      id,
      type,
      serial_number,
      macrometer,
      tags,
      created_at,
      setup,
      clientsLabel,
      listaClientes,
      selClient,
      version_id,
      listaVersao,
      selVersao,
      filters,
      types
    };
  },
};
</script>

<style>
.content{
  overflow-y: unset !important;
}
</style>
<style scoped>
.form-check-input {
  margin-right: 0.5rem;
}
.form-check {
  display: flex;
  justify-content: center;
}
h2 {
  margin: 1rem;
}
.btn-outline-success {
  border-color: #6EFF94;
  color: black;
  background-color: white;
}
.btn-outline-success:hover {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
}
.btn-check:checked + .btn-outline-success {
  background-color: #6EFF94;
  border-color: #6EFF94;
  color: black;
}
.btn-login {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
  line-height: 2rem;
  font-size: 1.1rem;
  margin: 1rem;
  place-self: center;
  width: 8rem;
  font-weight: 450;
}
.form-floating {
  margin: 1rem 0rem;
}

.form-floating > .form-control,
.form-floating> .form-select {
  background-color: #e8f0fe;
  color: black;
  line-height: 3rem;
  margin: 0rem 0.2rem;
}
.input-group > .form-control,
.input-group > .form-select {
  background-color: #e8f0fe;
  color: black;
  line-height: 3rem;
}
.input-group {
  margin: 1rem 0rem;
}
.multiselect {
  height: 3.63rem;
  margin: 1rem 0rem;
  background: #e8f0fe;
}
input::placeholder {
  color: black;
}
.input-group-text {
  background-color: #37393c;
  padding: 0.375rem 1.2rem;
}
.groupCadastros {
  text-align-last: center;
}
.dropdown-item {
  padding: 0rem 0rem;
}
.tableBox {
  padding: 0rem;
  box-shadow: 0px 4px 19px 4px rgb(0 0 0 / 20%);
  margin: 1rem;
}
.btn-filtrar {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
  margin: 1rem 0rem;
  width: 95%;
}
.btn-router {
  line-height: 2rem;
  width: 100%;
}
@media screen and (max-width: 500px) {
  .btn-group {
    display: block;
  }
  .head-btn {
    display: block;
  }
}
@media screen and (min-width: 501px) {
  .head-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
<style lang="scss" scoped>
::v-deep(.paginator-custom) {
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #6EFF94;
    border-color: #e3f2fd;
    color: #495057;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #495057;
    background: #6EFF94;
  }
}
::v-deep(.multiselect-custom) {
  .multiselect-placeholder {
    color: black;
    margin-top: 0.3rem
  }
  .multiselect-caret{
    margin-top: -0.5rem;
  }
  .multiselect-single-label{
    margin-top: 0.3rem;
  }
  .multiselect-clear{
    margin-top: -0.3rem;
  }
}
</style>