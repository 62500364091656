<template>
<Toast />
  <body>
    <div class="groupCadastros">
      <GroupbtnAdmin></GroupbtnAdmin>
    </div>
    <main>
      <div class="card col-sm-7" id="main_box">
        <div class="card-body">
          <p>Cadastrar Dispositivo</p>
          <div class="row">
          <p style="font-size:1rem">* Campos Obrigatórios</p>

            <div class="col-sm-6 VerComoList form-floating">
              <Multiselect
                :columns="{ container: 12, label: 4, wrapper: 6 }"
                v-model="tipo"
                placeholder="Selecione"
                class="multiselect-custom form-select"
                :options="tiposDispositivos"
                id="floatingInputGrid"
              />
              <label
                for="floatingSelect"
                style="margin-left: 15px; padding: 12px"
                >Tipo do Dispositivo *</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-floating">
                <InputText for="floatingInputGrid" type="text" v-model="deveui" class="form-control" placeholder="ID (DevEui) *" autocomplete="off" id="floatingInputGrid" v-tooltip.top="'Identico à Etiqueta'"/>
                <label for="floatingInputGrid">ID (Emei ou DevEui) *</label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating">
                <InputText for="floatingInputGrid" type="text" v-model="serial" class="form-control" placeholder="ID (DevEui) *" autocomplete="off" id="floatingInputGrid" v-tooltip.top="'Identico à Etiqueta'"/>
                <label for="floatingInputGrid">ID do Dispositivo (Serial Number) *</label>
              </div>
            </div>
            <div class="col-sm-6 VerComoList form-floating">
              <Multiselect
                :columns="{ container: 12, label: 4, wrapper: 6 }"
                v-model="selVersao"
                placeholder="Selecione"
                class="multiselect-custom form-select"
                :options="listaVersao"
                id="floatingInputGrid"
              />
              <label
                for="floatingSelect"
                style="margin-left: 15px; padding: 12px"
                >Versão *</label
              >
            </div>
            <div class="col-sm-6 VerComoList form-floating" v-if="isSuper == 1">
              <Multiselect
                :columns="{ container: 12, label: 4, wrapper: 6 }"
                v-model="selCliente"
                placeholder="Selecione"
                class="multiselect-custom form-select"
                :options="listaClientes"
                id="floatingInputGrid"
              />
              <label
                for="floatingSelect"
                style="margin-left: 15px; padding: 12px"
                >Clientes *</label
              >
            </div>
            <div class="col-md-6">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Setup"
                  autocomplete="off"
                  v-model="setup"
                  aria-describedby="addon-wrapping"
                  id="floatingInputGrid"
                />
                <label for="floatingInputGrid">Setup</label>
              </div>
            </div>
          </div>
          <div class="form-check">
        <input
          class="form-check-input"
          v-model="macrometer"
          type="checkbox"
          value=""
          id="flexCheckDefault"
        />
          <label class="form-check-label" for="flexCheckDefault"> Macromedidor </label>
        </div>
          <div class="d-grid gap-2 col-8 mx-auto">
            <button @click="submit" class="btn btn-login" type="button">
              Cadastrar
            </button>
          </div>
        </div>
      </div>
    </main>
  </body>
</template>

<script>
import { store } from "../services/store.js";
import { useLoading } from "vue-loading-overlay";
import Multiselect from "@vueform/multiselect";
import { createDevice, listClients, listSchema } from "../services/crud";
import { useToast } from "primevue/usetoast";
import InputText from 'primevue/inputtext';
import GroupbtnAdmin from "../components/groupbtnAdmin.vue";
import Toast from "primevue/toast";
const $loading = useLoading();
export default {
  components: {
    Multiselect,
    GroupbtnAdmin,
    Toast,
    InputText
  },
  name: "Login",
  props: {
    msg: String,
  },
  data() {
    return {
      isSuper: store.type_user == 1 ? true : false,
      client_id: "",
      user_id: "",
      selCliente: "",
      listaClientes: [],
      listaVersao: [],
      errors: [],
      tipo: "",
      deveui: "",
      serial: "",
      setup: "",
      tags: "",
      macrometer: false,
      errorForm: "",
      selVersao: "",
      listaDeClientes: [],
      tiposDispositivos: [
        { value: "flow", label: "Vazão" },
        { value: "pressure", label: "Pressão" },
        { value: "noise", label: "Ruído" },
      ],
    };
  },
  methods: {
    submit: async function () {
      if (!this.tipo) {
        this.errors.push(" Tipo do Dispositivo");
      }
      if (!this.deveui) {
        this.errors.push(" ID (DevEui)");
      }
      if (!this.serial) {
        this.errors.push(" ID do Dispositivo");
      }
      if (!this.selCliente) {
        this.errors.push(" Clientes");
      }

      if (this.errors.length === 1) {
        this.showErrorInput(this.errors)
      }

      if (this.errors.length > 1) {
        this.showErrorInputs(this.errors)
      }

      if (!this.isSuper) {
        this.selCliente = store.client_id;
      } 
      
      if (!this.errors.length) {
        const loader = $loading.show({});
        const dataForm = {
          id: this.deveui,
          type: this.tipo,
          serial_number: this.serial,
          client_id: this.selCliente,
          user_id: this.user_id,
          tags: this.tags,
          macrometer: this.macrometer,
          version: this.selVersao
        };

        if (this.setup.length > 0) {
          try {
            dataForm.setup = JSON.parse(this.setup);
            this.requestCreateDevice(dataForm)
          } catch (e) {
            this.showErrorSetup()
          }
        } else {
          dataForm.setup = {};
          this.requestCreateDevice(dataForm)
        }
        loader.hide();
        return true;
      } else {
        this.errors = [];
      }
    },
    async requestCreateDevice(dataForm){
      await createDevice(dataForm)
        .then((response) => {
          if (response.data.erro == false) {
            this.showsuccess();
            this.tipo = "";
            this.deveui = "";
            this.serial = "";
            this.tags = "";
            this.setup = "";
            this.selCliente = "";
            this.selVersao = "";
            this.macrometer = false;
          } else {
            this.showWarn(response.data.message)
          }
        })
        .catch((err) =>
          this.showErrorServer(err)
        );
    }
  },
  async mounted() {
    let auxS = [];
    let listS = [];
    await listSchema()
    .then((response) => {
      auxS = response.data;
      for (let i = 0; i < auxS.length; i++) {
        listS = {
          label: auxS[i].version,
          value: auxS[i].version,
        };
        this.listaVersao.push(listS);
      }
    })
    .catch((err) =>
      this.showErrorServer(err)
    );
    if (store.type_user) {
      let aux = [];
      let listClient = [];
      await listClients()
        .then((response) => {
          aux = response.data;
          for (let i = 0; i < aux.length; i++) {
            listClient = {
              label: aux[i].trading_name,
              value: aux[i].id,
            };
            this.listaClientes.push(listClient);
          }
        })
        .catch((err) =>
          this.showErrorServer(err)
        );
    }
    if (store.client_id) {
      this.client_id = store.client_id;
      this.user_id = store.user_id;
    }
  },
  setup(){
    const toast = useToast();
    const showError = () => {
      toast.add({severity:'error', summary: 'Error', detail:'Campos Obrigatórios não foram preenchidos!', life: 2500});
    }
    const showErrorServer = (err) => {
      toast.add({severity:'error', summary: 'Error', detail: err, life: 2500});
    }
    const showsuccess = () => {
      toast.add({severity:'success', detail:" Dispositivo cadastrado com sucesso! ", life: 2500});
    }
    const showErrorInput = (inputs) => {
      toast.add({severity:'error', detail:"O seguinte campo não foi preenchido corretamente: " + inputs + ".", life: 2500});
    }
    const showErrorInputs = (inputs) => {
      toast.add({severity:'error', detail:"Os seguintes campos não foram preenchidos corretamente: " + inputs + ".", life: 2500});
    }
    const showErrorSetup = () => {
      toast.add({severity:'error', detail:"Dados de entrada do Setup foram inseridos incorretamente.", life: 2500});
    }
    const showWarn = (res) => {
      toast.add({severity:'warn', detail:"Atenção!\n\n "+res+".", life: 2500});
    }
    return {showsuccess,showErrorServer,showError,showErrorInput,showErrorInputs,showErrorSetup,showWarn}
  }
};
</script>

<style scoped>
.form-check-input {
  margin-right: 0.5rem;
}
.form-check {
  display: flex;
  justify-content: center;
}
p{
  font-size: 0.7rem;
}
body {
  background-size: cover;
  height: 100vh;
  margin-top: -1rem;
}
.logo_stattus {
  margin: 3rem;
  width: 25%;
  height: 12vh;
}
.img_persona {
  height: 9rem;
  position: relative;
  margin-top: -6rem;
}
main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-body {
  display: flex;
  flex: 1 1 auto;
  padding: 1rem 1rem;
  flex-direction: column;
  align-items: center;
}
.btn-login {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
  line-height: 2rem;
  font-size: 1.1rem;
  margin: 1rem;
  place-self: center;
  width: 8rem;
  font-weight: 450;
}
.form-floating {
  margin: 1rem 0rem;
}

.form-floating > .form-control,
.form-floating > .form-select {
  background-color: #e8f0fe;
  color: black;
  line-height: 3rem;
  margin: 0rem 0.2rem;
}
.input-group > .form-control,
.input-group > .form-select {
  background-color: #e8f0fe;
  color: black;
  line-height: 3rem;
}
.input-group {
  margin: 1rem 0rem;
}
input::placeholder {
  color: black;
}
.input-group-text {
  background-color: #37393c;
  padding: 0.375rem 1.2rem;
}
.card {
  border-radius: 2.25rem;
  padding: 1rem;
  margin-bottom: 3rem;
  margin-top: 2rem;
}
.groupCadastros {
  text-align-last: center;
  padding-top: 3rem;
}
.dropdown-item {
  padding: 0rem 0rem;
}
p {
  font-size: 1.5rem;
}
.multiselect {
  height: 3.63rem;
  margin: 1rem 0rem;
  background: #e8f0fe;
  padding-right: 0.2rem;
}
.btn-outline-success {
  border-color: #6EFF94;
  color: black;
  background-color: white;
}
.btn-outline-success:hover {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
}
.btn-check:checked + .btn-outline-success {
  background-color: #6EFF94;
  border-color: #6EFF94;
  color: black;
}
.btn-router {
  line-height: 2rem;
  width: 100%;
}
@media screen and (max-width: 500px) {
  .btn-group {
    display: block;
  }
  .head-btn {
    display: block;
  }
}
@media screen and (min-width: 501px) {
  .head-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media only screen and (min-width: 575px) and (max-width: 875px) {
  #main_box {
    width: 52%;
  }
}
</style>
<style lang="scss" scoped>
::v-deep(.multiselect-custom) {
  .multiselect-placeholder {
    color: black;
    margin-top: 0.3rem
  }
  .multiselect-caret{
    margin-top: -0.5rem;
  }
  .multiselect-single-label{
    margin-top: 0.3rem;
  }
  .multiselect-clear{
    margin-top: -0.3rem;
  }
}
</style>