import { ROOT_API_TURING, ROOT_API_KDI } from "./config";

const KDI_PREFIX = ROOT_API_KDI;
const TURING_PREFIX = ROOT_API_TURING;

export const routes = {
    auth: {
        login: () => `${KDI_PREFIX}/auth/login`,
        remember: () => `${KDI_PREFIX}/auth/remember`
    },
    kdi: {
        public: {
            installPointsAll: () => `${KDI_PREFIX}/public/install_points/all`,
            installPointsFilter: () => `${KDI_PREFIX}/public/install_points/filter`,
            installPointsData: () => `${KDI_PREFIX}/public/install_points/data`,
            devicesInstallPoints: () => `${KDI_PREFIX}/public/devices-install-points`,
            filterDevicesByAlarmsOrReadings: () => `${KDI_PREFIX}/public/filter/alarms-or-readings`
        },
        changePassword: () => `${KDI_PREFIX}/users/change-password`,
        users: () => `${KDI_PREFIX}/users`,
        usersNew: () => `${KDI_PREFIX}/users/new`,
        usersAll: () => `${KDI_PREFIX}/users/all`,
        usersFilter: () => `${KDI_PREFIX}/users/filter`,
        usersFindOne: () => `${KDI_PREFIX}/users/find-one/all-information`,
        devices: () => `${KDI_PREFIX}/devices`,
        devicesAll: () => `${KDI_PREFIX}/devices/all`,
        devicesFilter: () => `${KDI_PREFIX}/devices/filter`,
        clients: () => `${KDI_PREFIX}/clients`,
        clientsAll: () => `${KDI_PREFIX}/clients/all`,
        clientsFilter: () => `${KDI_PREFIX}/clients/filter`,
        managerCommission: () => `${KDI_PREFIX}/manager/commission`,
        meterDeviceInstallPointAll: () => `${KDI_PREFIX}/meter_device_install_point/all`,
        meterDeviceInstallPointFilterClient: () => `${KDI_PREFIX}/meter_device_install_point/filter-client`,
        meterDeviceInstallPoint: () => `${KDI_PREFIX}/meter_device_install_point`,
        installPointsTags: () => `${KDI_PREFIX}/install_points`,
        installPointsAlt: () => `${KDI_PREFIX}/install_points`,
        installPointsFindOne: () => `${KDI_PREFIX}/install_points/find-one`,
        deviceSchemaFilter: () => `${KDI_PREFIX}/device-schema/filter`,
        tags: () => `${KDI_PREFIX}/tags`,
        tagsFilter: () => `${KDI_PREFIX}/tags/filter`,
        devicesMigration: () => `${KDI_PREFIX}/devices/migration`,
        meterDeviceInstallPointFilter: () => `${KDI_PREFIX}/meter_device_install_point/filter`,
        connectivityDataFilterCommunicationHistory: () => `${KDI_PREFIX}/connectivity_data/filter/communication_history`,
        conectivityDataFilterDevicesConnectivity: () =>  `${KDI_PREFIX}/conectivity_data/filter/devices-connectivity`,
        event_dataFilterEventLogs: () => `${KDI_PREFIX}/event_data/filter/event_logs`,
        batteryAndAlarms: (deviceId) => `${KDI_PREFIX}/device-state/${deviceId}`,
        commissionDetails: () => `${KDI_PREFIX}/meter_device_install_point/details`

    },
    turing: {
        public: {
            readings: () => `${TURING_PREFIX}/public/readings`,
            readingsAll: () => `${TURING_PREFIX}/public/readings/all`,
        },
        countAlarms: () => `${TURING_PREFIX}/device-state/count/alarms`,
        reportReadingsAll: () => `${TURING_PREFIX}/report/readings/all`,
        getReportInstallPoints: () => `${TURING_PREFIX}/report/install-points/devices`,
        devices_dataCalcAverageFlowHour: () => `${TURING_PREFIX}/devices_data/calc/average-flow-hour`,
        devices_dataCalcAverageFlowPerHour: () => `${TURING_PREFIX}/devices_data/calc/average-flow-per-hour`,
        devices_dataCalcAccumulatedFlow: () => `${TURING_PREFIX}/devices_data/calc/accumulated-flow`,
        connectivityRate: () => `${TURING_PREFIX}/connectivity_rate/by-client-or-devices`,
    },
};