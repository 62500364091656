<template>
  <div style="display: flex; justify-content: space-evenly; margin-bottom: 1rem">
    <div v-for="sensor in sensors" :key="sensor.name" v-show="isSensorVisible(sensor.name)">
      <input type="radio" :id="sensor.name" :value="sensor.name" @change="selectSensor(sensor.name)" :checked="isChecked(sensor.name)">
      <label :for="sensor.name">
        <img :src="getSensorImage(sensor.name)" width="50" height="50" style="cursor: pointer">
      </label>
    </div>
  </div>
</template>

<script>
import pressureOn from '../assets/pressure_On.png';
import pressureOff from '../assets/pressure_Off.png';
import flowOn from '../assets/flow_On.png';
import flowOff from '../assets/flow_Off.png';
import noiseOn from '../assets/noise_On.png';
import noiseOff from '../assets/noise_Off.png';
import macroOn from '../assets/macro_On.png';
import macroOff from '../assets/macro_Off.png';

export default {
  name: "FiltroSensores",
  emits: ['FiltraSensor'],
  props: {
    typeSensors: Array,
    verifyType: String,
  },
  data() {
    return {
      checkedNames: [],
      sensors: [
        { name: 'pressure', onImg: pressureOn, offImg: pressureOff },
        { name: 'flow', onImg: flowOn, offImg: flowOff },
        { name: 'noise', onImg: noiseOn, offImg: noiseOff },
        { name: 'macro', onImg: macroOn, offImg: macroOff }
      ]
    }
  },
  methods: {
    selectSensor(sensor) {
      this.checkedNames = [sensor];
      this.$emit('FiltraSensor', sensor);
    },
    resetSensor() {
      this.checkedNames = [];
    },
    isSensorVisible(sensorName) {
      return this.typeSensors.includes(sensorName);
    },
    isChecked(sensorName) {
      return this.checkedNames.includes(sensorName);
    },
    getSensorImage(sensorName) {
      const sensor = this.sensors.find(s => s.name === sensorName);
      return this.isChecked(sensorName) || this.typeSensors.length === 1 ? sensor.onImg : sensor.offImg;
    }
  },
  watch: {
    typeSensors() {
      this.checkedNames = [];
    },
    verifyType(data) {
      if (data) {
        this.checkedNames = [data];
      }
    }
  },
  created() {
    this.emitter.on('filter-reset', () => {
      this.resetSensor();
    });
  }
}
</script>

<style scoped>
input[type=radio] {
  display: none;
}

input[type=radio] + label {
  display: inline-block;
  padding: 0;
  height: 50px;
  width: 50px;
  background-size: 100%;
}

input[type=radio]:checked + label {
  height: 50px;
  width: 50px;
  background-size: 100%;
}
</style>