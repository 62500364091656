<template>
  <div v-for="sensor in sensors" :key="sensor.id" v-show="sensor.active" :id="sensor.elementId" style="text-align: -webkit-center;" class="map">
    <div class="guide-button">
      <div @click="updateData(sensor)" :id="`${sensor.elementId}-previous`" :class="sensor.canNavigatePrevious ? 'disabled' : 'button'">&#60;</div>
      <div style="align-self: end">{{ sensor.label }}</div>
      <div @click="updateData(sensor)" :id="`${sensor.elementId}-next`" :class="sensor.canNavigateNext ? 'disabled' : 'button'">&#62;</div>
    </div>
  </div>
</template>

<script>
import CalHeatMap from "cal-heatmap";
import moment from "moment";

export default {
  name: "CalHeatMaps",
  props: {
    dataInicial: String,
    dataF: Object,
    dataN: Object,
    dataP: Object,
    dataM: Object,
    selectedDevice: Object,
    tabX: String
  },
  data() {
    return {
      sensors: [
      { id: 'F', label: "Sensor de Vazão", data: this.dataF, heatmap: null, elementId: "cal-heatmapF", canNavigatePrevious: false, canNavigateNext: false, active: false, isUpdating: false },
      // Adicione `isUpdating` a cada sensor
      { id: 'N', label: "Sensor de Ruído", data: this.dataN, heatmap: null, elementId: "cal-heatmapN", canNavigatePrevious: false, canNavigateNext: false, active: false, isUpdating: false },
      { id: 'P', label: "Sensor de Pressão", data: this.dataP, heatmap: null, elementId: "cal-heatmapP", canNavigatePrevious: false, canNavigateNext: false, active: false, isUpdating: false },
      { id: 'M', label: "Sensor Macro", data: this.dataM, heatmap: null, elementId: "cal-heatmapM", canNavigatePrevious: false, canNavigateNext: false, active: false, isUpdating: false },
    ]
    };
  },
  watch: {
    dataF() { this.updateData(this.sensors[0]); },
    dataN() { this.updateData(this.sensors[1]); },
    dataP() { this.updateData(this.sensors[2]); },
    dataM() { this.updateData(this.sensors[3]); },
    selectedDevice() {
      this.sensors.forEach(sensor => this.updateData(sensor));
    },
  },
  methods: {
    async updateData(sensor) {
      sensor.data = await this.$props[`data${sensor.id}`];
      if (sensor.data && typeof sensor.data === 'object' && Object.keys(sensor.data).length > 0) {
        sensor.heatmap.update(sensor.data);
        sensor.active = true;
      } else {
        sensor.active = false;
      }
    },
    createHeatmapConfig(sensor) {
      return {
        itemSelector: `#${sensor.elementId}`,
        previousSelector: `#${sensor.elementId}-previous`,
        nextSelector: `#${sensor.elementId}-next`,
        subDomain: "x_day",
        legendMargin: [0, 10, 0, 0],
        legendVerticalPosition: "center",
        domainGutter: 10,
        domainMargin: 0,
        rowLimit: 10,
        legendOrientation: "vertical",
        label: { position: "top" },
        legendColors: { min: "#ff0000", max: "#6eff94", base: "#AEAEAD" },
        domain: "month",
        cellSize: 18,
        range: 3,
        start: new Date(this.dataInicial),
        minDate: new Date(moment(this.dataInicial).subtract(1, 'year').format('YYYY-MM-DD')),
        maxDate: new Date(moment().add(2, 'month').format('YYYY-MM')),
        legend: [1],
        itemName: ["comunicação", "comunicação"],
        subDomainTextFormat: "%d",
        subDomainTitleFormat: {
          empty: "Não é possível visualizar as comunicações anteriores ou superiores a 30 dias.",
          filled: "Há {count} comunicação(ões) em {date}."
        },
        legendTitleFormat: {
          lower: "Quando há 0 comunicação no dia.",
          upper: "Quando há mais que {max} comunicação no dia."
        },
        domainLabelFormat: date => moment(date).format("MMMM").toUpperCase(),
        subDomainDateFormat: date => moment(date).format("LL"),
        onMinDomainReached: (hit) => {
          sensor.canNavigatePrevious = hit;
        },
        onMaxDomainReached: (hit) => {
          sensor.canNavigateNext = hit;
        }
      };
    }
  },
  mounted() {
    this.sensors.forEach(sensor => {
      sensor.heatmap = new CalHeatMap();
      sensor.heatmap.init(this.createHeatmapConfig(sensor));
      this.updateData(sensor); 
    });
  }
};
</script>

<style>
.disabled {
  user-select: none;
  background-color: #d3d3d3; 
  border-color: #d3d3d3;    
  color: #a1a1a1;           
  cursor: not-allowed;       
  padding: 2px;
  font-size: 20px;
  padding: 0.275rem 0.75rem;
  height: 38px;
  width: 8%;
  border-radius: 5px;
}
.button {
  user-select: none;
  color: black;
  background-color: #6EFF94;
  border-color: #6EFF94;
  border-radius: 5px;
  padding: 2px;
  font-size: 20px;
  cursor: pointer;
  padding: 0.275rem 0.75rem;
  height: 38px;
  width: 8%;
}

.button:active {
  background: #6EFF94;
}

.guide-button {
  display: flex;
  justify-content: space-between;
}

#cal-heatmapF-next, #cal-heatmapN-next, #cal-heatmapP-next, #cal-heatmapM-next {
  font-weight: 800;
  margin-left: 1%;
}

#cal-heatmapF-previous, #cal-heatmapN-previous, #cal-heatmapP-previous, #cal-heatmapM-previous {
  font-weight: 800;
}

@media only screen and (min-height: 800px) {
  .guide-button {
    margin-bottom: 1vh;
    margin-top: 2vh;
  }

  .button {
    height: 42px;
  }
}
</style>
