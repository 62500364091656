import api from './api.js'
import { routes } from "../services/routes";

const getHeader = (token) => ({
    headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    }
})

export const userFindOneInfo = (email, token) => {
    let json = api.post(routes.kdi.usersFindOne(), { email }, getHeader(token));
    return json;
}

export default {
    userFindOneInfo
}
