<template>
  <div style="display: flex; align-items: center">
    <div class="periodicidade" >
      <div
        class="btn-group"
        role="group"
        aria-label="Basic radio toggle button group"
        v-if="type !== 'macro'"
      >
        <input
          type="radio"
          class="btn-check"
          :name="this.nameId + '-btnradioP'"
          :id="this.nameId + '-btnradio1'"
          autocomplete="off"
          value="hourly"
          v-model="this.periodo"
          @change="this.onValueChange"
        />
        <label
          class="btn btn-outline-light"
          :for="this.nameId + '-btnradio1'"
        >Hora</label
        >

        <input
          type="radio"
          class="btn-check"
          :name="this.nameId + '-btnradioP'"
          :id="this.nameId + '-btnradio2'"
          autocomplete="off"
          value="daily"
          v-model="this.periodo"
          @change="this.onValueChange"
        />
        <label class="btn btn-outline-light" :for="this.nameId + '-btnradio2'">Dia</label>

        <input
            type="radio"
            class="btn-check"
            :name="this.nameId + '-btnradioP'"
            :id="this.nameId + '-btnradio3'"
            autocomplete="off"
            value="weekly"
            v-model="this.periodo"
            @change="this.onValueChange"
        />
        <label class="btn btn-outline-light" :for="this.nameId + '-btnradio3'">Semana</label>
      </div>
      <div
        class="btn-group-void"
        role="group"
        aria-label="Basic radio toggle button group"
        v-if="type === 'macro'"
      >
      <div style="padding-left: 5px; padding-right: 5px;">
        <label class="btn-void btn-outline-light" style="justify-content: center;">Hora</label>
      </div>
      </div>
    </div>
    <div v-if="type !== 'macro'" class="col-sm-9 box_branco"></div>
    <div v-else class="col-sm-10 box_branco"></div>
  </div>
</template>

<script>
export default {
  name: "ChartPeriodButton",
  props: {
    onChange: Function,
    selected: String,
    nameIdPrefix: String,
    type: String
  },
  data() {
    return {
      periodo: this.selected ? this.selected : 'hourly',
      nameId: this.nameIdPrefix || Math.floor(Math.random() * 1000),
    };
  },
  methods: {
    onValueChange: function () {
      this.onChange && this.onChange(this.periodo);
    }
  }
}
</script>

<style scoped>
.periodicidade{
  padding: 1rem 1rem 1rem 0rem;
  width: fit-content;
}
.box_branco{
  background: white;
  height: 1.7rem;
}
.btn-group{
  height: 2rem;
  align-items: center;
  color: black;
  background: white;
}
.btn-group-void{
  height: 2rem;
  background: white;
}
.btn{
  padding: 1px 12px;
}
.btn-void{
  padding: 1px 12px 1px 12px;
  vertical-align: sub;
  background-color: #6eff94;
  border-color: #6eff94;
}
.btn-check:checked+.btn-outline-light {
  background-color: #6eff94;
  border-color: #6eff94;
  color: black;
}
.btn-outline-light{
  color: black;
}
</style>